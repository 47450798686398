let menuData = [
    {
        "title": "课程列表",
        "url": "/ra/courses",
    },
    {
        "title": "作业箱",
        "url": "/ra/report_boxes",
    },
    // {
    //     "title": "排课记录",
    //     "url": "/ra/schedule_records",
    // },
];

module.exports = menuData;
